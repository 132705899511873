import { useEffect, useMemo } from 'react'
import classnames from 'classnames'
import { Text } from '../../../../../..'
import { useListingSearchContext } from '../../../../../../../context/listingSearch'
import { useTrackingContext } from '../../../../../../../providers/tracking'
import styles from './SearchResult.module.scss'
import { useRecoilState } from 'recoil'
import {
  CurrentSelectedLocation,
  currentSelectedLocation
} from '../../../../../GoogleAutoComplete/state/currentSelectedLocation'
import { homeSearchMapState } from '../../../../../Listings/ListingsMap/state/homeSearchMapState'
import { STARTING_ZOOM } from '../../../../../../../constants/listings'
import { useSelector } from 'react-redux'
import { selectFilter } from '../../../../../../../store/selectors/filters'
import { useGetListingsForArea } from '../../../../../../../hooks/listings/useGetListingsForArea'
import {
  GeoAreaLevel,
  ListingsOrderBy,
  ListingsOrderDirection
} from '../../../../../../../api/gqlaxy/gql/generated/graphql'
import { convertAutocompleteLevelToGeoAreaLevel } from './convertAutocompleteLevelToGeoAreaLevel'
import { listingsSearchCriteria } from '../../../../../../../constants/listings'
import { useAppSelector } from '../../../../../../../store/hooks'

export interface SearchResultLocation {
  name: string
}

export interface SearchResult {
  id: string
  name?: string
  displayName: string
  maxPriceCents?: number
  minPriceCents?: number
  minBedroomsCount?: number
  location?: SearchResultLocation
}

export interface SearchResultProps {
  type: string
  index: number
  result: SearchResult
  isLastResult: boolean
  indexOfResultInFlattenResults: number
  setLeadListings: (listings: any) => void
  setLeadListingsIsFetching: (isFetching: boolean) => void
}

export const SearchResult = ({
  type,
  index,
  result,
  isLastResult,
  indexOfResultInFlattenResults,
  setLeadListings,
  setLeadListingsIsFetching
}: SearchResultProps) => {
  const { selectedIndex, handleKeyDown, handleOnClick, handleOnTouchStart } = useListingSearchContext()

  const { trackingClient } = useTrackingContext()

  const isLead = useAppSelector(state => state.auth.isLead)

  const focused = indexOfResultInFlattenResults === selectedIndex

  const [selectedLocation, setSelectedLocation] = useRecoilState(currentSelectedLocation)

  const [, setMapState] = useRecoilState(homeSearchMapState)

  const filter = useSelector(selectFilter)

  const searchCriteria = useMemo(
    () => ({
      page: 0,
      perPage: listingsSearchCriteria.SEARCH_RESULTS_PAGE_LIMIT,
      sortOrder: { orderBy: ListingsOrderBy.DaysOnMarket, orderDir: ListingsOrderDirection.Asc },
      filters: {
        statuses: ['Active', 'Coming Soon']
      },
      searchArea: {
        geoArea: {
          latitude: Number(selectedLocation?.lat),
          longitude: Number(selectedLocation?.lon),
          level: convertAutocompleteLevelToGeoAreaLevel(selectedLocation?.level),
          name: selectedLocation?.name ?? '',
          stateAbbreviation: selectedLocation?.stateAbbreviation ?? '',
          city: selectedLocation?.level === 'city' ? selectedLocation?.city : undefined,
          ...(convertAutocompleteLevelToGeoAreaLevel(selectedLocation?.level) === GeoAreaLevel.PostalCode
            ? { zipCode: selectedLocation?.id }
            : {})
        }
      }
    }),
    [selectedLocation]
  )

  const { data: listings, isFetching } = useGetListingsForArea(searchCriteria, { enabled: !!selectedLocation })
  useEffect(() => {
    if (listings) {
      setLeadListings(listings)
    }
  }, [listings, setLeadListings])

  useEffect(() => {
    setLeadListingsIsFetching(isFetching)
  }, [isFetching, setLeadListingsIsFetching])

  const handleClick = () => {
    setSelectedLocation(result as unknown as CurrentSelectedLocation)

    if (!isLead) {
      setMapState({
        center: {
          lat: filter?.location?.lat,
          lng: filter?.location?.lon
        },
        zoom: STARTING_ZOOM
      })
      handleOnClick(type, index)
    }
    trackingClient?.searchCompleted()
  }

  return (
    <>
      <li
        role='option'
        id={result.id}
        key={result.id}
        onClick={handleClick}
        aria-selected={focused}
        onKeyDown={handleKeyDown}
        onTouchStart={() => handleOnTouchStart(type, index)}
        className={classnames(styles.root, focused && styles.focused)}
      >
        <Text weight='semibold' element='span'>
          {result?.displayName}
        </Text>
      </li>
      {!isLastResult && <hr className={styles.divider} />}
    </>
  )
}
