import { ROUTE_KEY_BUY, ROUTE_KEY_HOME, ROUTE_KEY_HOME_SEARCH } from '../../../constants/routes'
import { useSelector } from 'react-redux'
import { useIntl, defineMessages } from 'react-intl'
import { selectHomeData } from '../../../store/selectors/home'
import { Icon, LinkButton, Box, Text, Flex } from '@homebotapp/hb-react-component-catalog'
import { selectRouteKey } from '../../../store/selectors/routes'
import { GLOBAL_AGENT_NAVIGATION } from '../../../constants/tracking/trackingEventLocations/globalTrackingEventLocation'
import { selectCustomerFirstName, selectName } from '../../../store/selectors/customerProfile'
import useViewport from '../../../hooks/useViewport'
import { Banner } from '../Banner/Banner'
import { selectBuyerId } from '../../../store/selectors/buyerInfo'
import { useLocation } from 'react-router'

export const MSG = defineMessages({
  back: {
    id: 'Navigation.customerProfile.backToClient',
    defaultMessage: 'Back to client'
  },
  previewingAs: {
    id: 'Navigation.customerProfile.previewingAs',
    defaultMessage: 'Previewing as'
  }
})

export const ImpersonationBanner = () => {
  const intl = useIntl()

  const { isLargeOrAbove } = useViewport()

  const home = useSelector(selectHomeData)
  const buyerId = useSelector(selectBuyerId)
  const customerName = useSelector(selectName)
  const customerFirstName = useSelector(selectCustomerFirstName)
  const name = isLargeOrAbove ? customerName : customerFirstName

  const routeKey = useSelector(selectRouteKey)
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const impId = params.get('impId')
  let backLink = ''
  //  once new clients experience is released, we can remove the conditional
  if (impId) {
    const baseUrl = process.env.REACT_APP_CUSTOMER_ADMIN_URI
    backLink = `${baseUrl}/clients/${impId}`
  } else {
    backLink = process.env.REACT_APP_MAIN_STREET_URI || 'https://admin.homebot.test'
    if (home?.id && routeKey === ROUTE_KEY_HOME) {
      backLink += `/homes/${home.id}`
    } else if (buyerId && (routeKey === ROUTE_KEY_BUY || routeKey === ROUTE_KEY_HOME_SEARCH)) {
      backLink += `/buyers/${buyerId}`
    } else {
      backLink += `/clients/manage/buyers`
    }
  }

  return (
    <Banner display='flex' justifyContent='space-between' alignItems='center' pl='2' pr='6'>
      <LinkButton
        variant='ghost'
        size='xs'
        color='white'
        href={backLink}
        leftIcon={<Icon name='angle-left' />}
        tracking={{
          guid: 'sKhBfRLvx7kVW7j5QoHgYj',
          ui_context: GLOBAL_AGENT_NAVIGATION,
          descriptor: MSG.back.defaultMessage
        }}
      >
        {intl.formatMessage(MSG.back)}
      </LinkButton>
      <Flex alignItems='baseline'>
        <Text size='3xs' as='span' color='white'>
          {intl.formatMessage(MSG.previewingAs)}
        </Text>
        &nbsp;
        <Text as='span' fontWeight='semibold' size='3xs' color='white'>
          {name}
        </Text>
      </Flex>
    </Banner>
  )
}
