import { enUS, es } from 'date-fns/locale'
import { formatDistanceToNowStrict } from 'date-fns'
import { defineMessages, useIntl } from 'react-intl'
import { Flex, Text } from '@homebotapp/hb-react-component-catalog'
import { Listing } from '../../../../api/gqlaxy/gql/generated/graphql'
import { StatusBadge } from '../../StatusBadge'
import { useAppSelector } from '../../../../store/hooks'
import { formatToPhone } from '../../Utils/format'

export const HEADER_ATTRIBUTION_MLS = ['NorthwestMLS', 'WillametteValley']

export const MSG = defineMessages({
  status: {
    id: 'HomeSearch.listingDetails.statusHeader.updatedAgo',
    defaultMessage: 'Updated {status} ago'
  },
  statusChecked: {
    id: 'HomeSearch.listingDetails.statusHeader.checkedAgo',
    defaultMessage: '• Refreshed 15 min ago'
  },
  listedBy: {
    id: 'HomeSearch.listingDetails.statusHeader.listedBy',
    defaultMessage: 'Listed by:'
  },
  boughtBy: {
    id: 'HomeSearch.listingDetails.statusHeader.boughtBy',
    defaultMessage: 'Buyer brokerage:'
  }
})

export interface StatusHeaderProps {
  listing?: Listing
}

export const StatusHeader = ({ listing }: StatusHeaderProps) => {
  const intl = useIntl()
  const locale = useAppSelector(state => state.locale.locale)
  // Replace date time format so that it includes T between date and time instead of space. Can remove once
  // squad-6 has converted all date time formats to standard format in SQUAD6-244
  const listingUpdatedAt = listing?.updatedAt?.replace(' ', 'T')
  const updatedAt = listingUpdatedAt && new Date(listingUpdatedAt)

  return (
    <>
      {listing?.originatingSystemName && HEADER_ATTRIBUTION_MLS.includes(listing.originatingSystemName) && (
        <>
          <Text mb={2} size='2xs'>
            {intl.formatMessage(MSG.listedBy)} {listing?.agent?.name},{' '}
            {formatToPhone(listing?.agent?.phone) ?? listing?.agent?.email ?? formatToPhone(listing?.brokerage?.phone)},{' '}
            {listing?.brokerage?.name}
            {' • '}
            {listing?.buyerBrokerageName && intl.formatMessage(MSG.boughtBy)} {listing?.buyerBrokerageName}
          </Text>
        </>
      )}
      <Flex gap={1} align={['flex-start', 'center']} direction={['column-reverse', 'row']}>
        {listing?.status && <StatusBadge status={listing.status} />}
        <Flex gap={1}>
          {updatedAt && (
            <Text mb={0} size='2xs'>
              {intl.formatMessage(MSG.status, {
                status: formatDistanceToNowStrict(updatedAt, { locale: locale === 'es' ? es : enUS })
              })}
            </Text>
          )}
          <Text mb={0} size='2xs'>
            {intl.formatMessage(MSG.statusChecked)}
          </Text>
        </Flex>
      </Flex>
    </>
  )
}
