import { Flex, LoadingSpinner, Grid, Text } from '@homebotapp/hb-react-component-catalog'
import { ListingCardNew } from '../ListingCardNew/ListingCardNew'
import { FeedType, ListingCompact } from '../../../api/gqlaxy/gql/generated/graphql'
import { defineMessages, useIntl } from 'react-intl'
import {  FeedEmptyState } from '../../FeedEmptyState/FeedEmptyState'

interface GalleryListProps {
  listings?: ListingCompact[]
  isLoading?: boolean
  /** optional; allows for impersonation of favorite listings */
  clientId?: string
  feedType: FeedType
}

export const MSG = defineMessages({
  noListingFound: {
    id: 'General.noListingFound',
    defaultMessage: 'No listings found'
  }
})

export const GalleryList = ({ listings, isLoading, clientId, feedType }: GalleryListProps) => {
  const intl = useIntl()
  if (isLoading) {
    return <LoadingSpinner variant='bot-head' size='lg' position='relative' />
  }

  if (!listings?.length) {
    return <FeedEmptyState feedType={feedType} />
  }

  return (
    <Flex ml={[0, 0]} mb={[0, 4]} direction={['column', 'row']} width='100%' justifyContent='space-between'>
      <Grid templateColumns={['repeat(1, 1fr)', null, 'repeat(2, 1fr)', null, 'repeat(4, 1fr)']} gap={4}>
        {listings?.map(listing => <ListingCardNew key={listing.id} listing={listing} clientId={clientId} />)}
      </Grid>
    </Flex>
  )
}
