import { useSelector } from 'react-redux'
import { useCallback } from 'react'
import { getHomeTrackingEventSubmitted, setHomeTrackingEventSubmitted } from '../actions/home'
import { ClientEventName } from '../api/gqlaxy/gql/generated/graphql'
import { useCreateClientEvent } from './useCreateClientEvent'
import { selectHomeUuid } from '../store/selectors/home'
import { selectAuthedAsClient } from '../store/selectors/auth'

type useCreateHomeClientEventOnceProps = {
  eventName: ClientEventName
  eventProperties?: Record<string, any>
  listingId?: string
}
// use useCreateHomeClientEvent if you want to create the event more than once per session
const useCreateHomeClientEventOnce = ({
  eventName,
  eventProperties = {},
  listingId
}: useCreateHomeClientEventOnceProps) => {
  const homeId = useSelector(selectHomeUuid)
  const tracked: 'true' | null = getHomeTrackingEventSubmitted(eventName)
  const authedAsCustomer = !useSelector(selectAuthedAsClient)

  const { mutateAsync: createClientEvent } = useCreateClientEvent({ disableErrorPrompts: true })

  return useCallback(() => {
    if (homeId && eventName && !authedAsCustomer && !tracked) {
      const clientEventInput: {
        homeId: string
        eventName: ClientEventName
        properties: Record<string, any>
        listingId?: string
      } = {
        homeId: homeId,
        eventName: eventName,
        properties: eventProperties
      }

      if (listingId) {
        clientEventInput.listingId = listingId
      }

      createClientEvent({
        clientEventInput: clientEventInput
      })
      setHomeTrackingEventSubmitted(eventName)
    }
  }, [eventName, listingId, homeId, tracked])
}

export default useCreateHomeClientEventOnce
