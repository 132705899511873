import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { createSelector } from 'reselect'
import { STATES_BY_ABBREV } from '../../../constants/states'
import { AVM_TUNE_TYPES, AVM_SOURCE_TYPES, CONFIDENCE_THRESHOLD } from '../../../constants/valuation'
import { getThe, getTheBoolean, getFrom, select } from '../utils'
import { selectCustomerFirstName, selectCustomerId, selectCustomerPhotoUri } from '../customerProfile'
import { getAuthEmail } from '../../../auth'
import { selectCurrentCELs, selectAvmWithoutCMA, sortMostRecentAVMs } from './dictionary'

const getHome = state => state.home
const selectFromHome = select(getHome)
export const selectHomeLoading = selectFromHome(getThe('loading'))
export const selectHomeError = selectFromHome(getThe('error'))
export const selectHomeFetchId = selectFromHome(getThe('fetchId'))
export const selectHomeIsErrored = selectFromHome(getTheBoolean('errorModal'))
export const selectHomeNotFound = selectFromHome(getTheBoolean('notFound'))

export const selectHomeData = selectFromHome(getThe('data'))
export const selectHasHomeData = selectFromHome(getTheBoolean('data'))

const getHomeData = state => state.home.data
export const selectFromHomeData = select(getHomeData)

export const getHomeId = getThe('id')
export const selectHomeId = selectFromHomeData(getHomeId)

export const selectMetadata = selectFromHomeData(getThe('metadata'))

export const getHomeUuid = getThe('uuid')
export const getHomeSoldDate = getThe('soldDate')
export const selectHomeUuid = selectFromHomeData(getHomeUuid)

const getAddress = getThe('address')
const getFromAddress = getFrom(getAddress)
const getZip = getFromAddress('zip')
const getState = getFromAddress('state')
export const getStreet = getFromAddress('street')
export const getLat = getFromAddress('lat')
export const getLng = getFromAddress('lng')
export const getUnit = getFromAddress('unit')

export const selectAddress = selectFromHomeData(getAddress)
export const selectZipcode = selectFromHomeData(getZip)
export const selectHomeAddressState = selectFromHomeData(getState)
export const selectStreet = selectFromHomeData(getStreet)
export const selectHomeAddressStateName = createSelector(selectHomeAddressState, abbrev =>
  get(STATES_BY_ABBREV, abbrev)
)
export const selectHomeLat = selectFromHomeData(getLat)
export const selectHomeLng = selectFromHomeData(getLng)
export const selectHomeUnit = selectFromHomeData(getUnit)
export const selectHomeSoldDate = selectFromHomeData(getHomeSoldDate)
export const selectValueOrigin = selectFromHomeData(getThe('valueOrigin'))

const getHomeUserFromAttribute = attrName => (home, attrValue) => {
  const def = get(home, 'users[0]')

  if (!(home && attrName && attrValue)) {
    return def
  }

  return find(home.users, { [attrName]: attrValue }) || def
}

export const getHomeUserFromEmail = getHomeUserFromAttribute('email')

export const selectCurrentHomeUser = createSelector(getHomeData, () => getAuthEmail(), getHomeUserFromEmail)

export const selectHasRecentLoan = createSelector(selectCurrentCELs, dict => dict.hasRecentLoan())

export const selectHomeCMA = selectFromHomeData(getThe('cma'))

export const selectAVMs = selectFromHomeData(getThe('avm'))
export const selectLoans = selectFromHomeData(getThe('loans'))
export const selectHasProcessedHome = selectFromHomeData(getThe('processed'))
export const selectHomeHasAvmsAndLoans = createSelector(selectAVMs, selectLoans, (avms, loans) => {
  return !isEmpty(avms) && !isEmpty(loans)
})

export const selectAgentValuations = createSelector(selectAVMs, avms =>
  avms ? avms.filter(a => a.source === AVM_SOURCE_TYPES.AGENT) : []
)
export const selectValuationBounds = createSelector(
  selectAvmWithoutCMA,
  selectAgentValuations,
  selectCustomerId,
  selectCustomerPhotoUri,
  (homebotAvm, agentValuations, customerId, customerPhotoUri) => {
    const rangedCMAs = agentValuations.filter(
      a => a.type === AVM_TUNE_TYPES.CMA && a.low > 0 && a.low < a.amount && a.high > a.amount
    )
    const sortedRangedCMAs = sortMostRecentAVMs(rangedCMAs)

    if (!homebotAvm && sortedRangedCMAs.length === 0) {
      return undefined
    }

    const backupAvm = homebotAvm || sortedRangedCMAs[sortedRangedCMAs.length - 1]

    let avmForRange = backupAvm
    if (rangedCMAs.length) {
      avmForRange = sortMostRecentAVMs(rangedCMAs)[0]
    }

    let forCustomer = {}
    if (agentValuations.length) {
      forCustomer = sortMostRecentAVMs(agentValuations)[0]
    }

    let usePhotoUri = null
    if (customerPhotoUri && customerId && customerId === forCustomer.authorId) {
      usePhotoUri = customerPhotoUri
    }

    return {
      homebotAmount: backupAvm.amount,
      customerAmount: forCustomer.amount,
      customerPhotoUri: usePhotoUri,
      high: avmForRange.high,
      low: avmForRange.low
    }
  }
)

export const selectLatestTunedAVM = createSelector(selectAVMs, avms => {
  if (!avms || !avms.filter) {
    return undefined
  }

  const manualAVMs = avms.filter(a => Object.values(AVM_TUNE_TYPES).includes(a.type)) // TODO: Move to BE
  return manualAVMs.length ? sortMostRecentAVMs(manualAVMs)[0] : undefined
})

const selectFromLatestTunedAVM = select(selectLatestTunedAVM)
export const selectLatestTunedType = selectFromLatestTunedAVM(getThe('type'))
export const selectLatestTunedSource = selectFromLatestTunedAVM(getThe('source'))
export const selectLatestTunedSourceObject = selectFromLatestTunedAVM(getThe('sourceObject'))
export const selectLatestTunedAuthorName = createSelector(
  selectLatestTunedSourceObject,
  selectCustomerFirstName,
  (sourceObject, customerFirstName) => {
    if (!sourceObject || isEmpty(sourceObject)) {
      return customerFirstName
    }

    const sourceFirstName = sourceObject.firstName

    if (sourceFirstName === customerFirstName) {
      return sourceFirstName
    } else {
      const sourceLastName = sourceObject.lastName
      return `${sourceFirstName} ${sourceLastName}`
    }
  }
)

export const selectLatestValuationHasConfidence = createSelector(
  selectAvmWithoutCMA,
  selectAgentValuations,
  (homebotAvm = {}, agentValuations) => {
    const hasAgentVal = Boolean(agentValuations && agentValuations.length)
    const homebotConfident = Boolean(homebotAvm && homebotAvm.confidence > CONFIDENCE_THRESHOLD)

    return hasAgentVal || homebotConfident
  }
)
