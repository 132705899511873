import React from 'react'
import LineScale from './LineScale'
import styles from './Loading.module.scss'

export const LoadingPanel = () => (
  <div className={styles.blockLoader}>
    <LineScale className={styles.loader} />
  </div>
)

export default LoadingPanel
