import { atom } from 'recoil'
import { StateKeys } from '../../../../constants/stateKeys'
import { StorageKey } from '../../../../constants/StorageKey'
import { storageEffect } from '../../../../effects/storageEffect'

export interface CurrentSelectedLocation extends google.maps.places.AutocompletePrediction {
  lat: number
  lon: number
  name: string
  placeId: string
  stateAbbreviation: string
  city?: string | null
  zipCode?: string | null
  level?: string
  id?: string | null
}

export const currentSelectedLocation = atom<CurrentSelectedLocation | undefined>({
  key: StateKeys.currentSelectedLocation,
  default: undefined,
  effects: [storageEffect(StorageKey.CurrentSelectedLocation)]
})
