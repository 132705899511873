import { memo } from 'react'
import { Image, Link, useTheme } from '@homebotapp/hb-react-component-catalog'
import { useSelector } from 'react-redux'
import { Link as ReactRouterLink } from 'react-router-dom'
import {
  selectCompanyName,
  selectCompanyBrandDarkLogoUri,
  selectCompanyBrandLightLogoUri
} from '../../../store/selectors/customerProfile'
import { useDigestUrl } from '../../../hooks/navigation/useDigestUrl'

export const BrandLogo = () => {
  const { theme } = useTheme()

  const homeownerOrBuyerDigestUrl = useDigestUrl()

  const companyName = useSelector(selectCompanyName)
  const darkBrandLogo = useSelector(selectCompanyBrandDarkLogoUri)
  const lightBrandLogo = useSelector(selectCompanyBrandLightLogoUri)
  const isLight = theme.isLightThemeType
  const source = isLight ? lightBrandLogo : darkBrandLogo

  return (
    <Link height='2rem' px='0' as={ReactRouterLink} to={homeownerOrBuyerDigestUrl}>
      <MemoedImage source={source} alt={companyName} />
    </Link>
  )
}

type MemoedImageProps = {
  source: string
  alt: string
}

const MemoedImage = memo(({ source, alt }: MemoedImageProps) => {
  return <Image height='32px' width='62px' src={source} alt={alt} />
})
