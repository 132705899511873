import { Box, Text } from '@homebotapp/hb-react-component-catalog'
import { ReactNode } from 'react'

interface ListingGalleryTileProps {
  icon: ReactNode
  title: string
  onClick?: () => void
}

export const ListingGalleryTile = ({ icon, title, onClick }: ListingGalleryTileProps) => {
  return (
    <Box
      shadow={2}
      _hover={{
        boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.4)',
        bg: 'neutral.200'
      }}
      onClick={onClick}
      cursor='pointer'
      px={4}
      py={2}
      borderWidth={1}
      borderRadius={16}
      width='fit-content'
      minWidth='150px'
      backgroundColor='neutral.100'
    >
      <Box pt={2}>{icon}</Box>
      <Text m={0} textColor='text-neutral-900' py={2} size='2xs' fontWeight={600}>
        {title}
      </Text>
    </Box>
  )
}
