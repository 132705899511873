import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from '@homebotapp/hb-react-component-catalog'
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { useState } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { Divider } from '../../shared/Navbar'
import { useRecoilState } from 'recoil'
import { recentlySelectedLocations } from './state/recentlySelectedLocations'
import { useTrackingContext } from '../../../providers/tracking'
import { CurrentSelectedLocation, currentSelectedLocation } from './state/currentSelectedLocation'

export interface GoogleAutoCompleteProps {
  onPlaceSelected: (place: any) => void
  onClose?: () => void
  types?: string[] // for available types, see: https://developers.google.com/maps/documentation/javascript/place-autocomplete#constrain-place-types
}

export const MSG = defineMessages({
  findAPlace: {
    id: 'General.findAPlace',
    defaultMessage: 'Find a place...'
  },
  cancel: {
    id: 'General.cancel',
    defaultMessage: 'Cancel'
  },
  recent: {
    id: 'General.recent',
    defaultMessage: 'Recent'
  },
  places: {
    id: 'General.places',
    defaultMessage: 'Places'
  },
  loading: {
    id: 'General.loading',
    defaultMessage: 'Loading...'
  },
  poweredByGoogle: {
    id: 'General.poweredByGoogle',
    defaultMessage: 'Powered by Google'
  }
})

const AddressOption = ({ address, onClick }) => {
  return (
    <Box
      // as={Button}
      // tracking={tracking}
      mb={1}
      _hover={{
        background: 'neutral.200'
      }}
    >
      <Box onClick={onClick} m={0} cursor='pointer'>
        <Text noOfLines={1} m={0} size='sm' fontWeight={500}>
          {address}
        </Text>
      </Box>
      <Divider style={{ height: 2, margin: '0' }} />
    </Box>
  )
}

export const GoogleAutoComplete = ({
  onPlaceSelected,
  onClose,
  types = ['locality', 'administrative_area_level_3', 'postal_code']
}: GoogleAutoCompleteProps) => {
  const intl = useIntl()
  const [searchTerm, setSearchTerm] = useState('')
  const { trackingClient } = useTrackingContext()

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE_KEY,
    debounce: 300,
    options: {
      input: searchTerm,
      componentRestrictions: {
        country: 'us'
      },
      types
    }
  })

  const [recentLocations, setRecentLocations] =
    useRecoilState<google.maps.places.AutocompletePrediction[]>(recentlySelectedLocations)
  const [, setCurrentLocation] = useRecoilState(currentSelectedLocation)

  const onClick = (place: CurrentSelectedLocation) => {
    trackingClient?.autocompleteInteraction({
      ui_context: 'Google_Auto_Complete',
      descriptor: place.description,
      action: 'Selected',
      guid: '3GtcZMbe9X4C7YUKKiRUEE'
    })
    setCurrentLocation(place)
    // check if the place is already in the recent locations
    if (recentLocations?.findIndex(r => r.description === place.description) === -1) {
      setRecentLocations([...(recentLocations || []), place].splice(-3))
    }
    onPlaceSelected(place)
  }

  return (
    <Modal closeOnOverlayClick isOpen onClose={() => onClose?.()}>
      <ModalOverlay />
      <ModalContent mx={4} borderRadius={16}>
        <Flex px={5} py={5} alignItems='center' flexDirection='row'>
          <Flex flex={1}>
            <Input
              size='sm'
              tracking={{
                guid: '3GtcZMbe9X4C7YUKKiAyjg',
                descriptor: 'Search for a place'
              }}
              background='neutral.200'
              flex={1}
              mr={5}
              name={MSG.findAPlace.id}
              value={searchTerm}
              onChange={e => {
                getPlacePredictions({ input: e.target.value })
                setSearchTerm(e.target.value)
              }}
              placeholder={intl.formatMessage(MSG.findAPlace)}
            />
          </Flex>

          <Text m={0} onClick={onClose} size='sm' cursor='pointer' textColor='primary.500'>
            <FormattedMessage id={MSG.cancel.id} defaultMessage={MSG.cancel.defaultMessage} />
          </Text>
        </Flex>

        <ModalBody>
          {recentLocations && (
            <Box>
              <Flex mb={3} alignItems='center'>
                <Icon mr={2} name='clock' color='neutral.500' />
                <Text m={0} size='sm' fontWeight={600}>
                  <FormattedMessage id={MSG.recent.id} defaultMessage={MSG.recent.defaultMessage} />
                </Text>
              </Flex>
              {recentLocations?.map((recent, index) => (
                <AddressOption
                  key={index}
                  address={recent.description}
                  onClick={() => {
                    onClick(recent as CurrentSelectedLocation)
                  }}
                />
              ))}
            </Box>
          )}

          {placePredictions.length > 0 && (
            <Box mt={5}>
              <Text my={2} size='sm' fontWeight={600}>
                <FormattedMessage id={MSG.places.id} defaultMessage={MSG.places.defaultMessage} />
              </Text>
              {isPlacePredictionsLoading && (
                <Text>
                  <FormattedMessage id={MSG.loading.id} defaultMessage={MSG.loading.defaultMessage} />
                </Text>
              )}
              {placePredictions.map((prediction, index) => (
                <AddressOption
                  key={index}
                  address={prediction.description}
                  onClick={() => {
                    onClick(prediction as CurrentSelectedLocation)
                  }}
                />
              ))}
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent='flex-end'>
            <Text size='sm' m={0} textColor='white'>
              {intl.formatMessage(MSG.poweredByGoogle)}
            </Text>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
