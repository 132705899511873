import React, { useEffect, Suspense } from 'react'
import { APP_DIR } from '../constants/routes'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { useTrackingContext } from '../providers/tracking'

// Components
import AuthCallback from './AuthCallback'
import LoadingApp from '../Components/shared/Loading/LoadingApp'
import { MockUI } from './MockUI'
import { ForYouContent } from '../features/ForYouContent/ForYouContent'
import { ForYouGallery } from '../features/ForYouGallery/ForYouGallery'
import { ListingSearchProvider } from '../context/listingSearch'
import { HomeSearchLandingPage } from '../features/preview/homeSearchLandingPage'

/*eslint-disable */
const Global = React.lazy(() => import('../Components/buyers/Global/Global' /* webpackChunkName: "Global" */))
const PreviewRoutes = React.lazy(() => import('../features/preview' /* webpackChunkName: "Preview" */).then(module => ({ default: module.PreviewRoutes })));
const Root = React.lazy(() => import('./Root' /* webpackChunkName: "Root" */).then(module => ({ default: module.Root })));
const App = React.lazy(() => import('./App' /* webpackChunkName: "App" */))
const NotFound = React.lazy(() => import('./NotFound' /* webpackChunkName: "NotFound" */).then(module => ({ default: module.NotFound })));
const SignUp = React.lazy(() => import('./SignUp' /* webpackChunkName: "SignUp" */))
const HomeSearch = React.lazy(() => import('../features/homeSearch' /* webpackChunkName: "HomeSearch" */))
const DownloadApp = React.lazy(() => import('./DownloadApp' /* webpackChunkName: "DownloadApp" */))
const ExternalRedirectLoading = React.lazy(() => import('./ExternalRedirect' /* webpackChunkName: "DownloadApp" */))
/*eslint-enable */

const PUBLIC_HOME_SEARCH_URL = 'www.hbreservices.com'

const getBuyerRedirect = props => `/${APP_DIR}/buy${props.match.url}`

const Routes = () => {
  const location = useLocation()
  const { trackingClient } = useTrackingContext()

  useEffect(() => {
    trackingClient?.pageViewed({
      page_location: location.pathname
    })
  }, [location.pathname])

  return (
    <Suspense fallback={<LoadingApp />}>
      <div>
        <Switch>
          <Route exact path='/mock' component={MockUI} />
          <Route
            exact
            path='/'
            render={() => {
              return window.location.host === PUBLIC_HOME_SEARCH_URL ? (
                <Redirect to={`/home-search/public`} />
              ) : (
                <Root />
              )
            }}
          />
          <Route exact path='/auth/callback' component={AuthCallback} />
          <Route exact path='/external-redirect-loading' component={ExternalRedirectLoading} />
          <Route path='/sign-up' component={SignUp} />
          <Route path='/appStoreRedirect' component={DownloadApp} />

          <Route
            path='/home-search/public'
            render={props => (
              <ListingSearchProvider>
                <HomeSearch match={props.match} isPublic={true} />
              </ListingSearchProvider>
            )}
          />
          <Route
            path='/home-search/lead/:customerProfileId'
            render={props => <HomeSearchLandingPage match={props.match} />}
          />
          <Route
            path='/home-search/:customerProfileId'
            render={props => (
              <ListingSearchProvider>
                <HomeSearch match={props.match} isPublic={false} />
              </ListingSearchProvider>
            )}
          />
          <Route exact path='/for-you/:customerProfileId' render={props => <ForYouContent match={props.match} />} />
          <Route
            path='/for-you/:customerProfileId/gallery/:galleryId'
            render={props => <ForYouGallery match={props.match} />}
          />
          <Route path='/preview' component={PreviewRoutes} />
          <Route path={`/${APP_DIR}`} component={App} />
          <Route exact path='/:buyerId([-0-9a-f]{36})?' render={props => <Redirect to={getBuyerRedirect(props)} />} />
          <Route render={() => <NotFound fullHeight />} />
        </Switch>
        <Global />
      </div>
    </Suspense>
  )
}

export default Routes
