import React, { MutableRefObject } from 'react'
import classnames from 'classnames'
import Slider, { CustomArrowProps, Settings } from 'react-slick'
import { IconAngleRight, IconAngleLeft } from '../Icon'

import styles from './Carousel.module.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// HACK: Guessing no one has updated the type library for react-slick post react 18 move...
const SliderTS: any = Slider

type CarouselProps = {
  sliderClassName?: string
  sliderRef?: MutableRefObject<Slider | null>
  slides: JSX.Element[]
} & Settings

interface CarouselSlideProps {
  slide: JSX.Element
}

const CarouselSlide = ({ slide }: CarouselSlideProps) => {
  return <>{slide}</>
}

const NextArrow = ({ onClick, className }: CustomArrowProps) => {
  return (
    <button
      className={classnames(
        styles.slickArrow,
        styles.slickNext,
        className?.includes('slick-disabled') && styles.disabled
      )}
      onClick={onClick}
      aria-label='next arrow button'
    >
      <IconAngleRight className={styles.icon} />
    </button>
  )
}

const PrevArrow = ({ onClick, className }: CustomArrowProps) => {
  return (
    <button
      className={classnames(
        styles.slickArrow,
        styles.slickPrev,
        className?.includes('slick-disabled') && styles.disabled
      )}
      onClick={onClick}
      aria-label='previous arrow button'
    >
      <IconAngleLeft className={styles.icon} />
    </button>
  )
}

const defaultResponsive = [
  {
    breakpoint: 420,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }
]

const Carousel = ({
  className,
  sliderClassName,
  slides,
  dots = false,
  arrows = false,
  infinite = false,
  speed = 500,
  slidesToScroll = 1,
  slidesToShow = 3,
  swipeToSlide = true,
  centerMode = false,
  draggable = false,
  responsive = defaultResponsive,
  sliderRef,
  ...restProps
}: CarouselProps) => {
  return (
    <div className={classnames(styles.carouselContainer, className)}>
      <SliderTS
        className={sliderClassName}
        dots={dots}
        arrows={arrows}
        infinite={infinite}
        speed={speed}
        slidesToScroll={slidesToScroll}
        slidesToShow={slidesToShow}
        swipeToSlide={swipeToSlide}
        draggable={draggable}
        centerMode={centerMode}
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
        responsive={responsive}
        ref={sliderRef}
        {...restProps}
      >
        {slides?.map((slide, index) => <CarouselSlide slide={slide} key={index} />)}
      </SliderTS>
    </div>
  )
}

export default Carousel
