import * as Sentry from '@sentry/browser'
import { useDispatch } from 'react-redux'
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Form,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  PhoneInput,
  Text
} from '@homebotapp/hb-react-component-catalog'
import { useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { HOME_SEARCH_LANDING_PAGE } from '../../../../../constants/tracking/trackingEventLocations/homeSearchTrackingEventLocation'
import { emailRegex } from '../../../../../constants/regexes'
import { useAppSelector } from '../../../../../store/hooks'
import { execute } from '../../../../../vendor/recaptcha'
import { createNewBuyerLead } from '../../../../../api/mikasa/token_requests'
import { BotHeadSpinWithMessage } from '../../../../shared/Loading/BotHeadSpinWithMessage'
import { PATH_HELPERS } from '../../../../../constants/navigation'
import { useRecoilState } from 'recoil'
import { currentSelectedLocation } from '../../../GoogleAutoComplete/state/currentSelectedLocation'
import history from '../../../../../history'
import { toggleListingSearchModal } from '../../../../../actions/listings'

const MSG = defineMessages({
  searchCount: {
    id: 'HomeSearch.landing.page.modal.count',
    defaultMessage: '{count}+ homes'
  },
  unlock: {
    id: 'HomeSearch.landing.page.modal.unlock',
    defaultMessage:
      '{count, plural, =1 {Unlock this result} =2 {Unlock both results} =3 {Unlock all # results} =4 {Unlock all # results} other {Unlock all {count}+ results}}'
  },
  unlockHomeDetails: {
    id: 'HomeSearch.landing.page.modal.unlockHomeDetails',
    defaultMessage: 'Unlock details for this home'
  },
  almostThere: {
    id: 'HomeSearch.landing.page.modal.almostThere',
    defaultMessage: 'Almost there!'
  },
  customerIntro: {
    id: 'HomeSearch.landing.page.modal.customer.intro',
    defaultMessage: 'Brought to you by {customerFullName}'
  },
  go: {
    id: 'HomeSearch.landing.page.modal.go',
    defaultMessage: 'Go'
  },
  letsGo: {
    id: 'HomeSearch.landing.page.modal.letsGo',
    defaultMessage: "Let's go"
  },
  firstName: {
    id: 'HomeSearch.landing.page.modal.firstName',
    defaultMessage: 'First name'
  },
  lastName: {
    id: 'HomeSearch.landing.page.modal.lastName',
    defaultMessage: 'Last name'
  },
  email: {
    id: 'HomeSearch.landing.page.modal.email',
    defaultMessage: 'Email address'
  },
  firstNameRequired: {
    id: 'HomeSearch.landing.page.modal.firstName.required',
    defaultMessage: 'First name is required'
  },
  lastNameRequired: {
    id: 'HomeSearch.landing.page.modal.lastName.required',
    defaultMessage: 'Last name is required'
  },
  emailRequired: {
    id: 'HomeSearch.landing.page.modal.email.required',
    defaultMessage: 'Email is required'
  },
  emailInvalid: {
    id: 'HomeSearch.landing.page.modal.email.invalid',
    defaultMessage: 'Please enter a valid email address.'
  },
  phone: {
    id: 'HomeSearch.landing.page.modal.phone',
    defaultMessage: 'Phone number'
  },
  optional: {
    id: 'HomeSearch.landing.page.modal.optional',
    defaultMessage: '(Optional)'
  }
})

export const SearchLeadCard = ({ listings, emailInputRef, isDrawer = false }) => {
  const intl = useIntl()
  const locale = intl?.locale

  const dispatch = useDispatch()

  const baseUrl = process.env.REACT_APP_CFE_BASE_URL

  const customerProfileId = useAppSelector(state => state.customerProfile?.data?.id)

  const [showNameInput, setShowNameInput] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)

  const [selectedLocation, _] = useRecoilState(currentSelectedLocation)

  const firstName = useAppSelector(state => state.customerProfile?.data?.firstName)
  const LastName = useAppSelector(state => state.customerProfile?.data?.lastName)
  const customerFullName = `${firstName} ${LastName}`

  const handleCreateBuyerClick = async data => {
    setLoading(true)

    // validate via recaptcha
    let validationToken

    try {
      validationToken = await execute({ action: 'property_search' })
    } catch (e) {
      setLoading(false)
      Sentry.captureException(e)

      return
    }

    // redirect current session user to public listings page
    const currentSessionRedirect = `${PATH_HELPERS.listings.buildPath(
      customerProfileId,
      selectedLocation?.level,
      selectedLocation?.city,
      {
        statuses: ['Active', 'Coming Soon']
      }
    )}`

    // redirect link for email
    const redirectUrl = `${baseUrl}${PATH_HELPERS.listings.buildPath(
      customerProfileId,
      selectedLocation?.level,
      selectedLocation?.city,
      {
        statuses: ['Active', 'Coming Soon']
      }
    )}`

    const params = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      mobile: data.phone,
      redirectUrl: redirectUrl,
      validationToken,
      locale,
      ...(data.phone && { mobile: data.phone })
    }

    try {
      await createNewBuyerLead(params)
      setLoading(false)
      // dispatch toggle so modal stays closed when navigating to listings page
    } catch (err) {
      setLoading(false)
      console.error(err)
      Sentry.captureException(err)
    }
    dispatch(toggleListingSearchModal(false))
    history.push(currentSessionRedirect)
  }

  return (
    <>
      {/* @ts-ignore */}
      <Card backgroundColor='var(--hb-colors-primary-50) !important'>
        <CardBody>
          {!loading && (
            <Box>
              <Flex alignItems='center'>
                <Icon name='home' color='primary.700' mr={2} />
                <Heading as='h4' mb={0} size='xs' variant='eyebrow' color='primary.700' textAlign='left'>
                  {isDrawer
                    ? showNameInput
                      ? intl.formatMessage(MSG.almostThere)
                      : intl.formatMessage(MSG.unlockHomeDetails)
                    : showNameInput
                    ? intl.formatMessage(MSG.almostThere)
                    : intl.formatMessage(MSG.unlock, { count: listings.length })}
                </Heading>
              </Flex>
              <Text>{intl.formatMessage(MSG.customerIntro, { customerFullName: customerFullName })}</Text>
              <Form onSubmit={handleCreateBuyerClick}>
                {({ formState: { errors } }) => (
                  <>
                    <Box display='flex' flexDirection='row'>
                      <FormControl mb={4} isInvalid={!!errors?.email}>
                        <Input
                          autoFocus
                          name='email'
                          ref={emailInputRef}
                          placeholder={intl.formatMessage(MSG.email)}
                          onKeyDown={e => {
                            if (e.key === 'Enter') {
                              e.preventDefault()
                              setShowNameInput(true)
                            }
                          }}
                          rules={{
                            pattern: {
                              value: emailRegex,
                              message: intl.formatMessage(MSG.emailInvalid)
                            },
                            required: {
                              value: true,
                              message: intl.formatMessage(MSG.emailRequired)
                            }
                          }}
                          tracking={{
                            guid: 'kRHkxmofEUi5Cp7Lqh5XDh',
                            ui_context: HOME_SEARCH_LANDING_PAGE,
                            descriptor: 'Email'
                          }}
                        />
                        <FormErrorMessage>{errors?.email?.message?.toString()}</FormErrorMessage>
                      </FormControl>
                      <Button
                        ml={2}
                        tracking={{
                          guid: 'vNUUJP4SxLmZeJabVnbG3A',
                          ui_context: HOME_SEARCH_LANDING_PAGE,
                          descriptor: 'Go button'
                        }}
                        onClick={() => setShowNameInput(true)}
                      >
                        {intl.formatMessage(MSG.go)}
                      </Button>
                    </Box>
                    {showNameInput && (
                      <Box>
                        <Flex flexDir={['column', null, 'row']} gap={[0, null, 4]}>
                          <FormControl isInvalid={!!errors?.firstName} mb={4}>
                            <FormLabel>{intl.formatMessage(MSG.firstName)}</FormLabel>
                            <Input
                              name='firstName'
                              placeholder={intl.formatMessage(MSG.firstName)}
                              rules={{
                                required: {
                                  value: true,
                                  message: intl.formatMessage(MSG.firstNameRequired)
                                }
                              }}
                              tracking={{
                                guid: 'eprhrbJot33FsfeAnBJ5ux',
                                ui_context: HOME_SEARCH_LANDING_PAGE,
                                descriptor: 'First name input'
                              }}
                            />
                            <FormErrorMessage>{errors?.firstName?.message?.toString()}</FormErrorMessage>
                          </FormControl>
                          <FormControl isInvalid={!!errors?.lastName} mb={4}>
                            <FormLabel>{intl.formatMessage(MSG.lastName)}</FormLabel>
                            <Input
                              name='lastName'
                              placeholder={intl.formatMessage(MSG.lastName)}
                              rules={{
                                required: {
                                  value: true,
                                  message: intl.formatMessage(MSG.lastNameRequired)
                                }
                              }}
                              tracking={{
                                guid: 'bpJNZV9AsJCc7d47EZyVS1',
                                ui_context: HOME_SEARCH_LANDING_PAGE,
                                descriptor: 'Last name input'
                              }}
                            />
                            <FormErrorMessage>{errors?.lastName?.message?.toString()}</FormErrorMessage>
                          </FormControl>
                        </Flex>
                        <FormControl mb={4}>
                          <Box display='flex' flexDirection='row'>
                            <FormLabel mr={2}>{intl.formatMessage(MSG.phone)}</FormLabel>
                            <Text>{intl.formatMessage(MSG.optional)}</Text>
                          </Box>
                          <PhoneInput
                            name='phone'
                            placeholder={intl.formatMessage(MSG.phone)}
                            tracking={{
                              guid: 'u9wLRmA5Ew41Tfkmn9aC4b',
                              ui_context: HOME_SEARCH_LANDING_PAGE,
                              descriptor: 'phone input'
                            }}
                          />
                        </FormControl>
                        <Button
                          type='submit'
                          width='100%'
                          colorScheme='primary'
                          tracking={{
                            guid: 'kRHkxmofEUi5Cp7Lqh5XDh',
                            ui_context: HOME_SEARCH_LANDING_PAGE,
                            descriptor: "Let's go button"
                          }}
                        >
                          {intl.formatMessage(MSG.letsGo)}
                        </Button>
                      </Box>
                    )}
                  </>
                )}
              </Form>
            </Box>
          )}
          {loading && (
            <Box display='flex' flex={1} alignItems='center' justifyContent='center'>
              <BotHeadSpinWithMessage />
            </Box>
          )}
        </CardBody>
      </Card>
    </>
  )
}
