import { useIntl, defineMessages } from 'react-intl'
import { useAppSelector } from '../../../store/hooks'
import { FooterText } from '../../shared/FooterText/FooterText'
import { Listing } from '../../../api/gqlaxy/gql/generated/graphql'
import equalHousingOpportunityImage from 'src/Components/shared/HousingType/equal-housing-opportunity.svg'
import { HBRELogo } from '../../shared/HBRE/HBRELogo'
import { ListingMetadata } from '../Compliance/ListingMetadata/ListingMetadata'
import { Box, Flex, Link, Text, Card, Heading, CardBody, Image } from '@homebotapp/hb-react-component-catalog'
import Phone from '../../shared/Phone'
import { subHours } from 'date-fns'
import { useSelector } from 'react-redux'
import { selectCustomerDisclaimer } from '../../../store/selectors/customerProfile'

const TREC_BROKERAGE_SERVICES_LINK = 'https://assets.homebotapp.com/files/trec_information_about_brokerage_services.pdf'
const TREC_PROTECTION_NOTICE_LINK = 'https://assets.homebotapp.com/files/trec_consumer_protection_notice.pdf'
const NY_PROCEDURE_LINK = 'https://assets.homebotapp.com/files/ny_standard_operating_procedure.pdf'
const NY_FAIR_HOUSING_LINK = 'https://assets.homebotapp.com/files/ny_fair_housing_notice.pdf'
const HB_RE_LICENSES_LINK = 'https://assets.homebotapp.com/files/homebot_state_re_licenses.pdf'
const DMCA_LINK = 'https://assets.homebotapp.com/files/digital_millenium_copyright_act_notice.pdf'

const HOMEBOT_PHONE = '720-432-1115'
const HOMEBOT_STREET = '1500 Market St'
const HOMEBOT_LOCATION = 'Denver, CO 80202'

export const MSG = defineMessages({
  linkedPDF: {
    id: 'PublicHomeSearchFooter.linkedPDF',
    defaultMessage: '[Linked PDF]'
  },
  linkedLicensesPDF: {
    id: 'PublicHomeSearchFooter.linkedLicensesPDF',
    defaultMessage: 'licenses'
  },
  linkedProceduresPDF: {
    id: 'PublicHomeSearchFooter.linkedProceduresPDF',
    defaultMessage: 'Procedures'
  },
  linkedNoticePDF: {
    id: 'PublicHomeSearchFooter.linkedNoticePDF',
    defaultMessage: 'Notice'
  },
  info: {
    id: 'PublicHomeSearchFooter.info',
    defaultMessage:
      "The information being provided is for consumers' personal, non-commercial use and may not be used for any purpose other than to identify prospective properties that consumers may be interested in purchasing."
  },
  hbre: {
    id: 'PublicHomeSearchFooter.hbre',
    defaultMessage: 'HB Real Estate Services Inc. holds real estate brokerage {link} in multiple states.'
  },
  nyStandard: {
    id: 'PublicHomeSearchFooter.nyStandard',
    defaultMessage: '§ 442-H New York Standard Operating {link}'
  },
  nyFair: {
    id: 'PublicHomeSearchFooter.nyFair',
    defaultMessage: '§ 442-I New York Fair Housing {link}'
  },
  linkedBrokerageServicesPDF: {
    id: 'PublicHomeSearchFooter.linkedBrokerageServicesPDF',
    defaultMessage: 'Information About Brokerage Services'
  },
  linkedProtectionNoticePDF: {
    id: 'PublicHomeSearchFooter.linkedProtectionNoticePDF',
    defaultMessage: 'Consumer Protection Notice'
  },
  trec: {
    id: 'PublicHomeSearchFooter.trec',
    defaultMessage: 'TREC: {brokerageServicesLink}, {consumerProtectionLink}'
  },
  cal: {
    id: 'PublicHomeSearchFooter.cal',
    defaultMessage: 'California DRE #02211496'
  },
  rights: {
    id: 'PublicHomeSearchFooter.rights',
    defaultMessage: '2023 HB Real Estate Services Inc. All rights reserved.'
  },
  alt: {
    id: 'PublicHomeSearchFooter.alt',
    defaultMessage: 'Homebot Real Estate Services'
  },
  contact: {
    id: 'PublicHomeSearchFooter.contact',
    defaultMessage: 'HB Real Estate Services Inc.'
  },
  privacy: {
    id: 'PublicHomeSearchFooter.privacy',
    defaultMessage: 'Privacy Policy {link}'
  },
  terms: {
    id: 'PublicHomeSearchFooter.terms',
    defaultMessage: 'Terms of Service {link}'
  },
  dmcaDisclaimer: {
    id: 'PublicHomeSearchFooter.dmcaDisclaimer',
    defaultMessage: 'DMCA Disclaimer'
  },
  constellationDisclaimer: {
    id: 'PublicHomeSearchFooter.constellationDisclaimer',
    defaultMessage: 'Powered by Constellation Web Solutions, Inc'
  }
})

export interface DisclaimerObject {
  mlsLogo: string
  disclaimer: string
}

export interface HomeSearchFooterProps {
  isListingsPage?: boolean
  specialDisclaimers?: Record<string, DisclaimerObject>
  listing?: Listing
}

export const HomeSearchFooter = ({
  listing,
  specialDisclaimers = {},
  isListingsPage = false
}: HomeSearchFooterProps) => {
  const intl = useIntl()
  const isPublic = useAppSelector(state => state.auth?.isPublic)
  const customerDisclaimer = useSelector(selectCustomerDisclaimer)

  return (
    <Box mt={8} mx='auto' maxW='67rem'>
      <footer>
        <hr />
        <Flex
          gap={8}
          px={2}
          pb='2rem'
          justifyContent='center'
          direction={isListingsPage ? 'column' : ['column-reverse', null, 'row']}
        >
          <Box flexBasis={listing ? '66.66%' : '100%'}>
            <Text mb={0} size='2xs'>
              {customerDisclaimer}
            </Text>
            <Text size='2xs'>{intl.formatMessage(MSG.info)}</Text>
            <Text size='2xs'>
              {intl.formatMessage(MSG.hbre, {
                link: (
                  <Link size='2xs' isExternal href={HB_RE_LICENSES_LINK}>
                    {intl.formatMessage(MSG.linkedLicensesPDF)}
                  </Link>
                )
              })}
            </Text>
            <Text size='2xs' marginBottom={0}>
              {intl.formatMessage(MSG.nyStandard, {
                link: (
                  <Link size='2xs' isExternal href={NY_PROCEDURE_LINK}>
                    {intl.formatMessage(MSG.linkedProceduresPDF)}
                  </Link>
                )
              })}
            </Text>
            <Text size='2xs'>
              {intl.formatMessage(MSG.nyFair, {
                link: (
                  <Link size='2xs' isExternal href={NY_FAIR_HOUSING_LINK}>
                    {intl.formatMessage(MSG.linkedNoticePDF)}
                  </Link>
                )
              })}
            </Text>
            <Text size='2xs'>
              {intl.formatMessage(MSG.trec, {
                brokerageServicesLink: (
                  <Link size='2xs' isExternal href={TREC_BROKERAGE_SERVICES_LINK}>
                    {intl.formatMessage(MSG.linkedBrokerageServicesPDF)}
                  </Link>
                ),
                consumerProtectionLink: (
                  <Link size='2xs' isExternal href={TREC_PROTECTION_NOTICE_LINK}>
                    {intl.formatMessage(MSG.linkedProtectionNoticePDF)}
                  </Link>
                )
              })}
            </Text>
            <Text mb={0} size='2xs'>
              {intl.formatMessage(MSG.cal)}
            </Text>
            <Link mb={10} size='2xs' isExternal href={DMCA_LINK}>
              {intl.formatMessage(MSG.dmcaDisclaimer)}
            </Link>
            <Image src={equalHousingOpportunityImage} height={50} width={50} mb={5} />
            {Object.keys(specialDisclaimers).map(item => {
              return (
                <Flex direction={'row'} mb={5}>
                  <img src={specialDisclaimers[item].mlsLogo} style={{ paddingRight: 10, maxHeight: 50 }} />
                  <Text mb={2} size='2xs' key={item}>
                    {specialDisclaimers[item]?.disclaimer?.replace(
                      '{{date}}',
                      subHours(new Date(), 1).toLocaleString()
                    )}{' '}
                    {intl.formatMessage(MSG.constellationDisclaimer)}
                  </Text>
                </Flex>
              )
            })}
            <Card size='lg' my={6}>
              <CardBody padding={4}>
                <Flex align='center' justify='space-around' direction={['column', 'column', 'row']}>
                  <Box mb={[8, 8, 0]}>
                    <HBRELogo maxWidth={48} />
                  </Box>
                  <Box>
                    <Heading ml={1} size='xs' marginBottom={0}>
                      {intl.formatMessage(MSG.contact)}
                    </Heading>
                    <Text ml={1} size='sm' marginBottom={0}>
                      {HOMEBOT_STREET}
                    </Text>
                    <Text ml={1} size='sm' marginBottom={0}>
                      {HOMEBOT_LOCATION}
                    </Text>
                    <Phone value={HOMEBOT_PHONE} />
                  </Box>
                </Flex>
              </CardBody>
            </Card>
            <FooterText noHomebotReferences={isPublic ? true : false} />
          </Box>
          {listing && (
            <Box flexBasis='33.33%'>
              <ListingMetadata listing={listing} />
            </Box>
          )}
        </Flex>
      </footer>
    </Box>
  )
}
