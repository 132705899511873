import { useState } from 'react'
import { App } from './App'
import { Provider } from 'react-redux'
import store from '../store'
import { CustomIntlProvider } from '../providers/intl'
import { Router } from 'react-router-dom'
import history from '../history'
import { ErrorBoundary } from './ErrorBoundary'
import { TrackingProvider } from '../providers/tracking'
import { legacyThemes, LEGACY_THEME_NAMES } from '@homebotapp/hb-react-component-catalog'
import { InjectTrackingIntoSwashbuckle } from '../providers/injectTrackingIntoSwashbuckle'
import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink } from '@apollo/client'

const createApolloClient = () => {
  return new ApolloClient({
    link: new HttpLink({
      uri: 'https://arriving-mule-33.hasura.app/v1/graphql',
      headers: {
        'Hasura-Client-Name': 'hasura-console' // WARNING: This header seems to fix sporadically getting field not found errors for new fields in Hasura (i.e. AGENT_PHONE at this specific time Nov 30, 2023). I have no clue why, but figured adding this was better than causing the page to blow up randomly for our clients half of the time.
      }
    }),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            // Customize the "LISTINGS" field
            // so that incoming listings are merged into the
            // existing list items.
            //
            // https://www.apollographql.com/docs/react/pagination/core-api#defining-a-field-policy
            LISTINGS: {
              // Don't cache separate results based on
              // any of this field's arguments.
              keyArgs: ['$locationId', 'limit'],
              // Concatenate the incoming list items with
              // the existing list items.
              merge(existing, incoming, { args: { offset = 0 } }) {
                // Slicing is necessary because the existing data is
                // immutable, and frozen in development.
                const merged = existing ? existing.slice(0) : []
                for (let i = 0; i < incoming.length; ++i) {
                  merged[offset + i] = incoming[i]
                }
                return merged
              }
            }
          }
        }
      }
    })
  })
}

export const AppContainer = () => {
  const [isError, setIsError] = useState(false)
  const [client] = useState(createApolloClient())

  let initialThemeName: string
  try {
    initialThemeName = localStorage?.getItem('LOADING_THEME') ?? LEGACY_THEME_NAMES.HB_DEFAULT
  } catch (e) {
    initialThemeName = LEGACY_THEME_NAMES.HB_DEFAULT
  }
  const initialTheme = legacyThemes[initialThemeName]

  return (
    <ErrorBoundary setIsError={setIsError}>
      <Provider store={store}>
        {/* We have to pass in a theme here or else Chakra will
          set the wrong mode in local storage and mess things up
        */}
        <ApolloProvider client={client}>
          <CustomIntlProvider>
            <TrackingProvider>
              <InjectTrackingIntoSwashbuckle theme={initialTheme}>
                <Router history={history}>
                  <App error={isError} />
                </Router>
              </InjectTrackingIntoSwashbuckle>
            </TrackingProvider>
          </CustomIntlProvider>
        </ApolloProvider>
      </Provider>
    </ErrorBoundary>
  )
}
