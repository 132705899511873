import { RefObject, useState } from 'react'
import classnames from 'classnames'
import { SearchResultsList } from './SearchResultsList'
import { SearchResultsEmpty } from './SearchResultsEmpty'
import { useListingSearchContext } from '../../../../../context/listingSearch'
import { BotHeadSpinWithMessage } from '../../../../shared/Loading/BotHeadSpinWithMessage'
import { LeadListingsSearchResults } from '../LeadListingsSearchResults/LeadListingsSearchResults'

import styles from './SearchModalResults.module.scss'
import { useAppSelector } from '../../../../../store/hooks'

interface SearchModalResultsProps {
  locationSearchInputRef?: RefObject<HTMLInputElement> | null | undefined
}

export const SearchModalResults = ({ locationSearchInputRef }: SearchModalResultsProps) => {
  const [leadListings, setLeadListings] = useState([])
  const [leadListingsIsFetching, setLeadListingsIsFetching] = useState(false)

  const isLead = useAppSelector(state => state.auth.isLead)

  const { loading, isInEmptyState, isInNoResultsState } = useListingSearchContext()

  const isEmpty = isInEmptyState || isInNoResultsState

  return (
    <div className={classnames(styles.results, isEmpty && styles.center)}>
      {loading && <BotHeadSpinWithMessage className={styles.loader} />}
      {!loading && isEmpty && <SearchResultsEmpty />}
      {!loading && !isEmpty && (
        <SearchResultsList setLeadListings={setLeadListings} setLeadListingsIsFetching={setLeadListingsIsFetching} />
      )}
      {!loading && !isEmpty && isLead && leadListings.length > 0 && (
        <LeadListingsSearchResults leadListings={leadListings} locationSearchInputRef={locationSearchInputRef} />
      )}
    </div>
  )
}
