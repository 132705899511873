import { useEffect, useMemo, useState } from 'react'
import { AppLayout } from '../../App/Layout/AppLayout'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveNavigationTab } from '../../store/slices/navigation'
import { NAVIGATION_TAB_NAMES } from '../../types/navigation'
import { Box, Button, Flex, Heading, Image, Stack, Text } from '@homebotapp/hb-react-component-catalog'
import { ListingGalleryTile } from '../../Components/homeSearch/ListingGalleryTile/ListingGalleryTile'
import isEmpty from 'lodash/isEmpty'
import recentPriceDrop from '../../assets/homes/img/priceDrop.svg'
import savedSearch from '../../assets/homes/img/savedSearch.svg'
import favorites from '../../assets/homes/img/favorites.svg'
import recentlySold from '../../assets/homes/img/recentlySold.svg'
import { defineMessages, useIntl } from 'react-intl'
import history from '../../history'
import { GoogleAutoComplete } from '../../Components/homeSearch/GoogleAutoComplete/GoogleAutoComplete'
import { useRecoilState } from 'recoil'
import {
  CurrentSelectedLocation,
  currentSelectedLocation
} from '../../Components/homeSearch/GoogleAutoComplete/state/currentSelectedLocation'
import { useGetUserFeeds } from '../../hooks/gallery/useGetUserFeeds'
import { FeedBase } from '../../api/gqlaxy/gql/generated/graphql'
import { Feed } from '../../Components/homeSearch/Feed/Feed'
import LoadingPanel from '../../Components/shared/Loading/LoadingBlock'
import { RootState } from '../../types/rootState'
import { selectNativeAccessFeature } from '../../store/selectors/customerProfile'
import useViewport from '../../hooks/useViewport'
import { DownloadMobileAppPromptCard } from '../../Components/homeSearch/ListingDetails/DownloadMobileAppPromptCard/Desktop/DownloadMobileAppPromptCard'
import { MobileDownloadMobileAppPromptCard } from '../../Components/homeSearch/ListingDetails/DownloadMobileAppPromptCard/Mobile/MobileDownloadMobileAppPromptCard'
import { HomeSearchFooter } from '../../Components/homeSearch/HomeSearchFooter/HomeSearchFooter'
import { selectClientFirstHome, selectClientHomes } from '../../store/selectors/client'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { useTrackingContext } from '../../providers/tracking'
import { getClientIdFromBearerToken, isAuthTokenSupported } from '../../auth'
import { fetchProfile } from '../../actions/customerProfile'
import { fetchClientData, fetchClientHomes, setCurrentClient } from '../../actions/client'
import { addToast } from '../../actions/toasts'
import { NoPreviewModeModal } from '../../Components/NoPreviewModeModal/NoPreviewModeModal'
import { MarketRecordZipCode } from '../../types/marketRecords'
import { fetchBuyerInfoData } from '../../actions'
import { selectMarketInterestZipcodes } from '../../store/selectors/buyerInfo'
import { fetchMarketRecordsDetail } from '../../actions/marketRecords'
import { getBuyerInfoData } from '../../api/mikasa/requests'
import { useLocation } from 'react-router'
import { SEARCH_PATH_HELPERS } from '../../constants/navigation'
import { useChangeFeedsLocation } from '../../hooks/gallery/useChangeFeedsLocation'

interface Tile {
  icon: JSX.Element
  label: string
  type?: string
  onClick?: () => void
}

export const MSG = defineMessages({
  for: {
    id: 'Gallery.for',
    defaultMessage: 'For'
  },
  concessions: {
    id: 'Gallery.concessions',
    defaultMessage: 'Buyer perks'
  },
  concessionsDescription: {
    id: 'Gallery.concessionsDescription',
    defaultMessage: 'Sellers cover part of your expenses'
  },
  recentPriceDrop: {
    id: 'Gallery.recentPriceDrop',
    defaultMessage: 'Price Drops'
  },
  recentPriceDropDescription: {
    id: 'Gallery.recentPriceDropDescription',
    defaultMessage: 'See places with a reduced asking price'
  },
  assumables: {
    id: 'Gallery.assumables',
    defaultMessage: 'Assumable Loans'
  },
  assumablesDescription: {
    id: 'Gallery.assumablesDescription',
    defaultMessage:
      "Ask your lender if you qualify for an assumable mortgage. You may be able to save by taking over the seller's low-rate loan!"
  },
  savedSearch: {
    id: 'Gallery.savedSearch',
    defaultMessage: 'Saved Search'
  },
  favorites: {
    id: 'Gallery.favorites',
    defaultMessage: 'Favorites'
  },
  recentlySold: {
    id: 'Gallery.recentlySold',
    defaultMessage: 'Recently Sold'
  },
  recentlySoldDescription: {
    id: 'Gallery.recentlySoldDescription',
    defaultMessage: 'Explore to see what the market is like'
  },
  yourStuff: {
    id: 'Gallery.yourStuff',
    defaultMessage: 'Your Stuff'
  },
  localGallery: {
    id: 'Gallery.localGallery',
    defaultMessage: 'Local Gallery'
  },
  forYou: {
    id: 'Gallery.forYou',
    defaultMessage: 'For You'
  },
  help: {
    id: 'Gallery.help',
    defaultMessage: 'Let’s find your next home'
  },
  localGalleries: {
    id: 'Gallery.localGalleries',
    defaultMessage: 'Local Galleries'
  },
  changeLocation: {
    id: 'Gallery.changeLocation',
    defaultMessage: 'Change Location'
  },
  failedChangeLocation: {
    id: 'Gallery.failedChangeLocation',
    defaultMessage: 'Failed to change feed location'
  },
  errorCreatingFeeds: {
    id: 'Gallery.errorCreatingFeeds',
    defaultMessage: 'Error creating feeds'
  }
})

const LocalGalleryIcon = {
  recentPriceDrop: recentPriceDrop,
  recentlySold: recentlySold,
  assumables: recentPriceDrop,
  concessions: recentPriceDrop
}

export const ForYouContent = ({ match }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const location = useLocation()
  const { isMediumOrAbove } = useViewport()
  const { customerProfileId } = match.params
  const homes = useSelector(selectClientHomes)
  const { trackingClient } = useTrackingContext()
  const home = useSelector(selectClientFirstHome)
  const clientIdFromToken = getClientIdFromBearerToken()
  const queryParams = new URLSearchParams(location.search)
  const clientZipcodes = useSelector(selectMarketInterestZipcodes)
  const clientId = clientIdFromToken || queryParams.get('clientId')
  const [loadingMarketInterests, setLoadingMarketInterests] = useState(true)
  const [showGooglePlaceSelector, setShowGooglePlaceSelector] = useState(false)
  const hasNativeAccess = useSelector<RootState, boolean>(selectNativeAccessFeature)
  const [favoriteMarkets, setFavoriteMarkets] = useState([] as MarketRecordZipCode[])
  const [currentLocation, setCurrentLocation] = useRecoilState(currentSelectedLocation)
  const { mutateAsync: changeFeedsLocation, isLoading: changingFeedsLocation } = useChangeFeedsLocation()
  const [showNotAuthorizedModal, setShowNotAuthorizedModal] = useState(!isAuthTokenSupported())
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_KEY
  })

  const {
    data,
    isFetching,
    refetch: refreshFeeds
  } = useGetUserFeeds(
    { googlePlaceId: currentLocation?.placeId ?? placePredictions?.[0]?.place_id, webUserId: clientId },
    { enabled: isAuthTokenSupported() && !!placePredictions?.[0] }
  )

  useEffect(() => {
    if (clientZipcodes) {
      dispatch(fetchMarketRecordsDetail(clientZipcodes))
    }
  }, [clientZipcodes, dispatch])

  const loadMarketInterests = async () => {
    try {
      const response = await getBuyerInfoData(clientId)
      setFavoriteMarkets(response?.data?.data?.marketInterests)
    } catch {
    } finally {
      // Delay finish loading to allow setting the favorite markets
      setTimeout(() => {
        setLoadingMarketInterests(false)
      }, 1000)
    }
  }

  useEffect(() => {
    if (!isAuthTokenSupported()) {
      setLoadingMarketInterests(false)
      return setShowNotAuthorizedModal(true)
    }

    loadMarketInterests()
    dispatch(fetchBuyerInfoData(clientId))
    if (customerProfileId) {
      dispatch(fetchProfile(customerProfileId))
    }
  }, [dispatch, customerProfileId])

  useEffect(() => {
    if (clientId) {
      dispatch(setCurrentClient(clientId))
      dispatch(fetchClientData(clientId, customerProfileId))
      dispatch(fetchClientHomes(clientId))
    }
  }, [dispatch, clientId, customerProfileId])

  useEffect(() => {
    if ((!isEmpty(favoriteMarkets) || home || homes) && !placePredictions?.[0] && !loadingMarketInterests) {
      !isEmpty(favoriteMarkets)
        ? getPlacePredictions({ input: favoriteMarkets[0].zipcode, types: ['postal_code'] })
        : home?.address
        ? getPlacePredictions({ input: `${home?.address?.street}, ${home?.address?.zip}` })
        : homes?.[0]?.address
        ? getPlacePredictions({ input: homes?.[0]?.address?.zip, types: ['postal_code'] })
        : null
    } else if (
      !isFetching &&
      !isPlacePredictionsLoading &&
      !placePredictions?.[0] &&
      data?.feeds?.length &&
      !loadingMarketInterests
    ) {
      getPlacePredictions({ input: data?.feeds?.[0]?.location?.name as string })
    }
  }, [
    home,
    data?.feeds,
    favoriteMarkets,
    isFetching,
    isPlacePredictionsLoading,
    placePredictions,
    loadingMarketInterests
  ])

  useEffect(() => {
    if (!isFetching && data?.feeds?.length) {
      // Set the current location to the first feed location
      setCurrentLocation(data?.feeds[0]?.location as unknown as CurrentSelectedLocation)
    }
  }, [data?.feeds, isFetching])

  const galleryTiles = useMemo(() => {
    const tiles = data?.feeds?.map(feed => {
      return {
        icon: <Image loading='lazy' src={LocalGalleryIcon[feed?.feedType as string]} alt={feed?.feedType as string} />,
        label: intl.formatMessage(MSG[feed?.feedType as string]),
        type: feed?.feedType,
        onClick: () => {
          trackingClient?.linkInteraction({
            ui_context: 'Gallery_Feed',
            descriptor: `Clicked on Feed ${feed?.feedType}`,
            action: 'Clicked',
            guid: `3GtcZMbe9HLD7YUKKiAyjg_${feed?.feedType}`
          })
          history.push(SEARCH_PATH_HELPERS.gallery.buildPath(customerProfileId, feed?.id, clientId))
        }
      }
    }) as Tile[]
    return tiles?.filter((tile, index, self) => self.findIndex(t => t.type === tile.type) === index)
  }, [data?.feeds, clientId])

  useEffect(() => {
    dispatch(setActiveNavigationTab(NAVIGATION_TAB_NAMES.FORYOU))
  }, [dispatch])

  const onLocationChange = async (place: { place_id: string; description: string }) => {
    if (!isAuthTokenSupported()) {
      return
    }
    setShowGooglePlaceSelector(false)
    if (!data?.feeds) return
    try {
      getPlacePredictions({ input: place.description })
      await changeFeedsLocation({
        feedIds: data?.feeds?.map(feed => feed?.id as string),
        googlePlaceId: place.place_id
      })
      refreshFeeds()
    } catch {
      dispatch(
        addToast({
          message: intl.formatMessage(MSG.failedChangeLocation),
          status: 'error'
        })
      )
    }
  }

  const tilesCategories = useMemo(() => {
    return {
      category: {
        title: intl.formatMessage(MSG.yourStuff),
        tiles: [
          {
            icon: <Image loading='lazy' src={savedSearch} alt='savedSearch' />,
            label: intl.formatMessage(MSG.savedSearch),
            onClick: () => {
              trackingClient?.linkInteraction({
                ui_context: 'Gallery_SavedSearch_Card',
                descriptor: 'Clicked on Saved Search',
                action: 'Clicked',
                guid: '3GtpqMPqaHLD7YUKKiAyjg'
              })
              history.push(`/home-search/${customerProfileId}/saved-searches`)
            }
          },
          {
            icon: <Image loading='lazy' src={favorites} alt='favorites' />,
            label: intl.formatMessage(MSG.favorites),
            onClick: () => {
              trackingClient?.linkInteraction({
                ui_context: 'Gallery_Favorites_Card',
                descriptor: 'Clicked on Favorites',
                action: 'Clicked',
                guid: '3GtcZMPqaHLD7YUKKiAyjg'
              })
              history.push(SEARCH_PATH_HELPERS.favoriteListings.buildPath(customerProfileId, clientId))
            }
          }
        ] as Tile[]
      },
      local: {
        title: intl.formatMessage(MSG.localGalleries),
        tiles: galleryTiles
      }
    }
  }, [data?.feeds])

  if (isFetching || changingFeedsLocation || loadingMarketInterests || !currentLocation || !data?.feeds) {
    return (
      <Box position='absolute' top='50%' left='50%'>
        <LoadingPanel />
      </Box>
    )
  }

  return (
    <AppLayout loading={false} enableAgentDropdown match={match}>
      {showNotAuthorizedModal && <NoPreviewModeModal />}
      {showGooglePlaceSelector && (
        <GoogleAutoComplete onClose={() => setShowGooglePlaceSelector(false)} onPlaceSelected={onLocationChange} />
      )}
      <Box pt={10} px={[5, 20]}>
        <Flex pb={10} alignItems='center' justifyContent='space-between'>
          <Box>
            <Text m={0} fontWeight={600} size={'xl'}>
              {intl.formatMessage(MSG.forYou)}
            </Text>
            <Text m={0} size='xs'>
              {intl.formatMessage(MSG.help)}
            </Text>
          </Box>
          <Box flex={[1, 0.3]}>
            <Text noOfLines={2} textAlign='right' size={['2xs', 'md']} m={0}>
              {`${intl.formatMessage(MSG.localGalleries)} `}
              {`${intl.formatMessage(MSG.for)} `}
              {currentLocation?.name || currentLocation?.description}
            </Text>
            <Text
              cursor='pointer'
              onClick={() => setShowGooglePlaceSelector(true)}
              textColor='primary.500'
              float='right'
              size='xs'
              m={0}
            >
              {intl.formatMessage(MSG.changeLocation)}
            </Text>
          </Box>
        </Flex>
        <Stack
          mx={-20}
          mb={10}
          borderWidth={1}
          borderStyle='solid'
          bg='neutral.100'
          direction='row'
          py={5}
          px={20}
          spacing={2}
          overflowX='auto'
          flexWrap='nowrap'
        >
          {Object.keys(tilesCategories).map(key => {
            const category = tilesCategories[key] as { title: string; tiles: Tile[] }
            if (!category.tiles?.length) return null
            return (
              <Box key={key}>
                <Box>
                  <Text fontWeight={600} m={0} size='md'>
                    {category.title}
                  </Text>
                </Box>
                <Stack direction='row' spacing={2}>
                  {category.tiles.map((tile, index) => (
                    <ListingGalleryTile key={index} icon={tile.icon} title={tile.label} onClick={tile.onClick} />
                  ))}
                </Stack>
              </Box>
            )
          })}
        </Stack>
        <Box>
          <Heading m={0} fontWeight={600} mr={1} size={['sm', 'lg']} textAlign='left'>
            {intl.formatMessage(MSG.localGallery)}
          </Heading>
          <Text m={0} size={['2xs', 'md']} textColor='neutral.400'>
            {intl.formatMessage(MSG.for)} {currentLocation?.name || currentLocation?.description}
          </Text>
          <Button onClick={() => setShowGooglePlaceSelector(true)} mt={2} size='xs'>
            {intl.formatMessage(MSG.changeLocation)}
          </Button>
        </Box>
        {data?.feeds?.map(feed => {
          return <Feed key={feed?.id} feed={feed as FeedBase} />
        })}
      </Box>
      {hasNativeAccess && (
        <Box margin={8}>
          {isMediumOrAbove ? (
            <DownloadMobileAppPromptCard isLandingPage={true} />
          ) : (
            <MobileDownloadMobileAppPromptCard isLandingPage={true} />
          )}
        </Box>
      )}
      <HomeSearchFooter />
    </AppLayout>
  )
}
