import { EffectCallback, useEffect, useRef } from 'react'
import { getBuyerTrackingEventSubmitted, setBuyerTrackingEventSubmitted } from '../actions/buyer'

/**
 * Custom hook to execute effect only once per session when specified condition is met.
 *
 * This is useful when you want to perform an effect only when some data is ready.
 */
export const useEffectOncePerSessionConditionally = (
  effect: EffectCallback,
  effectKey: string, // key to track if the effect has been triggered during the session
  enabled: boolean // flag that determines if the effect should run
): void => {
  const triggered = useRef(false)

  useEffect(() => {
    if (triggered.current || !enabled) {
      return
    }
    const tracked = getBuyerTrackingEventSubmitted(effectKey)
    if (tracked) {
      return
    }

    // Mark the effect as executed for the current session
    setBuyerTrackingEventSubmitted(effectKey)

    triggered.current = true
    return effect()
  }, [effect, enabled, effectKey])
}
