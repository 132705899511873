import { Box, Button, ButtonGroup, Flex, Icon, Image } from '@homebotapp/hb-react-component-catalog'
import { defineMessages, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useSendListingMessage } from '../../../hooks/listings/useSendListingMessage'
import { useEffect, useState } from 'react'
import {
  selectCustomerFirstName,
  selectCustomerId,
  selectCustomerIsRealEstateAgent,
  selectCustomerPhotoUri
} from '../../../store/selectors/customerProfile'
import { MessageTopicKey } from '../../../api/gqlaxy/gql/generated/graphql'
import * as Sentry from '@sentry/browser'
import { ConfettiTarget } from '../../shared/Confetti'

const MSG = defineMessages({
  cancel: {
    id: 'GenericFeedDirectMessage.cancel',
    defaultMessage: 'Cancel'
  },
  confirm: {
    id: 'GenericFeedDirectMessage.confirm',
    defaultMessage: 'Confirm'
  },
  buyerPerksDm: {
    id: 'GenericFeedDirectMessage.cta',
    defaultMessage: 'Ask {name} for details'
  },
  success: {
    id: 'GenericFeedDirectMessage.success',
    defaultMessage: 'Direct message sent!'
  },
  fail: {
    id: 'GenericFeedDirectMessage.fail',
    defaultMessage: 'Direct message failed to send.'
  }
})

/**
 * DM animation is based off EstimatedMonthlyPayment
 *
 * Goal is to eventually move this whole thing to the DS
 */
enum DMStatus {
  Initial = 'initial',
  Confirmation = 'confirmation',
  Success = 'success',
  Fail = 'fail'
}

// this let's the confetti animation finish before resetting the state
const debounce = (func, delay) => {
  let timer
  return function (...args) {
    clearTimeout(timer)
    timer = setTimeout(() => {
      // @ts-ignore
      func.apply(this, args)
    }, delay)
  }
}

const animationTimer = 200

export interface GenericFeedDirectMessageProps {
  topic: string
}

export const GenericFeedDirectMessage = ({ topic }: GenericFeedDirectMessageProps) => {
  const intl = useIntl()
  const { mutateAsync: sendBuyerMessage } = useSendListingMessage()

  const [isSending, setIsSending] = useState(false)
  const [dmStatus, setDmStatus] = useState<DMStatus>(DMStatus.Initial)
  const [showMiniConfetti, setShowMiniConfetti] = useState(false)
  const [showMiniConfettiAlt, setShowMiniConfettiAlt] = useState(false)
  const [confettiCounter, setConfettiCounter] = useState(0)

  const customerPhoto = useSelector(selectCustomerPhotoUri)
  const customerFirstName = useSelector(selectCustomerFirstName)
  const customerId = useSelector(selectCustomerId)
  const topicKey = MessageTopicKey.BuyerGeneralInquiryToCustomer

  const handleFinalStatus = () => {
    if (dmStatus === DMStatus.Fail) {
      setDmStatus(DMStatus.Initial)
    }

    if (dmStatus === DMStatus.Success) {
      setConfettiCounter(prevCounter => prevCounter + 1)
      const actionIndex = confettiCounter % 4

      switch (actionIndex) {
        case 0:
          setShowMiniConfetti(true)
          setShowMiniConfettiAlt(false)
          break
        case 1:
          setShowMiniConfettiAlt(true)
          break
        case 2:
          setShowMiniConfetti(true)
          break
        case 3:
          setShowMiniConfettiAlt(true)
          setTimeout(() => {
            if (confettiCounter % 4 === 3) {
              setShowMiniConfetti(false)
              setShowMiniConfettiAlt(false)
            }
          }, animationTimer)
          break
      }
    }
  }

  const debouncedHandleFinalStatus = debounce(handleFinalStatus, animationTimer)

  const getTransformationStyles = (): React.CSSProperties => {
    let transformation = 'translateY(0%)'

    switch (dmStatus) {
      case DMStatus.Initial:
        break
      case DMStatus.Confirmation:
        transformation = 'translateY(-37%)'
        break
      case DMStatus.Fail:
        transformation = 'translateY(-73.5%)'
        break
      case DMStatus.Success:
        transformation = 'translateY(-73.5%)'
        break
    }

    return {
      transform: transformation
    }
  }

  useEffect(() => {
    getTransformationStyles()
  }, [isSending, dmStatus])

  const handleSubmit = async () => {
    setIsSending(true)

    try {
      await sendBuyerMessage({
        input: {
          customerId: customerId,
          topicKey: topicKey,
          message: 'Can you tell me more about this listing gallery?',
          topic: topic
        }
      })
      setDmStatus(DMStatus.Success)
    } catch (error: any) {
      setDmStatus(DMStatus.Fail)

      Sentry.captureMessage('DM failed to send', {
        extra: {
          error: error.message || '',
          requestData: error.config?.data || {},
          requestErrors: Object.values(error.response?.data?.errors) || []
        }
      })
    } finally {
      setTimeout(() => {
        setIsSending(false)
      }, 300)
    }
  }

  return (
    <Flex direction='column' align='center' maxW='25rem' w='100%'>
      {/* @ts-ignore */}
      <ConfettiTarget go={dmStatus === DMStatus.Success} numberOfPieces={40}>
        {/* @ts-ignore */}
        <ConfettiTarget go={showMiniConfetti} numberOfPieces={32}>
          {/* @ts-ignore */}
          <ConfettiTarget go={showMiniConfettiAlt} numberOfPieces={72}>
            <Box position={'relative'} h={12} mb={0} overflow={'hidden'} w={'100%'}>
              <Flex
                direction={'column'}
                gap={4}
                style={getTransformationStyles()}
                transition={'.15s ease-in-out transform'}
              >
                <Flex direction='column' gap={4}>
                  <Box transition={'.15s ease-in-out transform'}>
                    <Button
                      colorScheme='secondary'
                      width='100%'
                      visibility={dmStatus === DMStatus.Initial ? 'visible' : 'hidden'}
                      isLoading={isSending}
                      variant={'outline'}
                      bg='neutral.50'
                      onClick={() => setDmStatus(DMStatus.Confirmation)}
                      tracking={{
                        guid: '7swvuJPai8GQmXBDP3LoBz',
                        ui_context: 'Buyer Perks DM',
                        descriptor: 'Initial Button Press to send message'
                      }}
                      leftIcon={<Image src={customerPhoto} h={6} w={6} borderRadius='50%' />}
                    >
                      {intl.formatMessage(MSG.buyerPerksDm, { name: customerFirstName })}
                    </Button>
                  </Box>
                  <ButtonGroup
                    width={'100%'}
                    transition={'1s ease-in-out opacity'}
                    opacity={dmStatus === DMStatus.Fail ? 0 : '100%'}
                  >
                    <Box overflow={'hidden'} width={isSending ? 0 : '50%'} transition={'.1s ease-in-out all'}>
                      <Button
                        colorScheme='secondary'
                        variant={'outline'}
                        w={isSending ? 0 : '100%'}
                        transition={'.2s ease-in-out all'}
                        px={6}
                        tracking={{
                          guid: 'vB7HBAvRvzMqACSmi7Guvv',
                          ui_context: 'Buyer Perks DM',
                          descriptor: 'Cancel submission of dm'
                        }}
                        bg='neutral.50'
                        visibility={dmStatus === DMStatus.Confirmation ? 'visible' : 'hidden'}
                        onClick={() => setDmStatus(DMStatus.Initial)}
                        disabled={isSending || dmStatus === DMStatus.Confirmation}
                      >
                        {intl.formatMessage(MSG.cancel)}
                      </Button>
                    </Box>
                    <Button
                      w='100%'
                      onClick={() => handleSubmit()}
                      colorScheme='primary'
                      isLoading={isSending}
                      visibility={dmStatus === DMStatus.Confirmation ? 'visible' : 'hidden'}
                      opacity={'100% !important'}
                      disabled={dmStatus !== DMStatus.Confirmation}
                      tracking={{
                        guid: 'h1nbMM77hTs72F3ZiJ1AfR',
                        ui_context: 'Buyer Perks DM',
                        descriptor: 'Confirm direct message submission'
                      }}
                    >
                      {intl.formatMessage(MSG.confirm)}
                    </Button>
                  </ButtonGroup>
                </Flex>
                <Flex>
                  <Button
                    w='100%'
                    variant='outline'
                    colorScheme='secondary'
                    bg='neutral.50'
                    tracking={{
                      guid: 'fFfazMFdoUZafKgRxVNGSm',
                      ui_context: 'Buyer Perks DM',
                      descriptor: 'Clicked on failure / success button'
                    }}
                    visibility={dmStatus === DMStatus.Success || dmStatus === DMStatus.Fail ? 'visible' : 'hidden'}
                    disabled={dmStatus === DMStatus.Confirmation || dmStatus === DMStatus.Initial}
                    leftIcon={
                      dmStatus === DMStatus.Success ? <Icon name='check-circle-fa' width={3} height={3} /> : undefined
                    }
                    onClick={() => debouncedHandleFinalStatus()}
                  >
                    {dmStatus === DMStatus.Success && intl.formatMessage(MSG.success)}
                    {dmStatus === DMStatus.Fail && intl.formatMessage(MSG.fail)}
                  </Button>
                </Flex>
              </Flex>
            </Box>
          </ConfettiTarget>
        </ConfettiTarget>
      </ConfettiTarget>
    </Flex>
  )
}
