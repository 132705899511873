import { Box, Card, Icon, Text } from '@homebotapp/hb-react-component-catalog'

export const LandingPageIconCard = ({ iconName, description }) => {
  return (
    <Card
      position='relative'
      width={['100%', null, null, '350px']}
      pt={[8, null, null, 10]}
      pb={[4, null, null, 6]}
      px={[2, null, null, 6]}
      my={[6, null, null, 4]}
    >
      <Box
        bg='brand.50'
        pt={[3, null, null, 4]}
        pr={[3, null, null, 4]}
        pb={[2, null, null, 3]}
        pl={[3, null, null, 4]}
        position='absolute'
        top={['-30px', null, null, '-38px']}
        left={'20px'}
        borderRadius={'50%'}
      >
        <Icon name={iconName} color='brand.500' height={[6, null, null, 9]} width={[6, null, null, 10]} />
      </Box>
      <Text mb={0} color='neutral.900'>
        {description}
      </Text>
    </Card>
  )
}
