import { useEffect, useRef, useState } from 'react'
import {
  Box,
  Drawer,
  Grid,
  GridItem,
  DrawerBody,
  DrawerContent,
  DrawerOverlay
} from '@homebotapp/hb-react-component-catalog'

import { ListingCardNew } from '../../../ListingCardNew/ListingCardNew'
import { defineMessages, useIntl } from 'react-intl'
import Text from '../../../../shared/Text'
import { SearchLeadCard } from '../SearchLeadCard/SearchLeadCard'
import { HOME_SEARCH_LANDING_PAGE } from '../../../../../constants/tracking/trackingEventLocations/homeSearchTrackingEventLocation'

import styles from './LeadListingsSearchResults.module.scss'

const MSG = defineMessages({
  searchCount: {
    id: 'HomeSearch.landing.page.home.count',
    defaultMessage: '{count}+ homes'
  },
  unlock: {
    id: 'HomeSearch.landing.page.home.unlock',
    defaultMessage: 'Unlock all {count}+ results'
  },
  customerIntro: {
    id: 'HomeSearch.landing.page.home.customer.intro',
    defaultMessage: 'Brought to you by {customerFullName}'
  }
})

export const LeadListingsSearchResults = ({ leadListings, locationSearchInputRef }) => {
  const intl = useIntl()

  const [showLeadsModal, setShowLeadsModal] = useState(false)

  const displayedResults = leadListings.slice(0, 4)

  const emailInputRef = useRef<HTMLInputElement>()

  useEffect(() => {
    if (showLeadsModal) {
      emailInputRef?.current?.focus()
    } else {
      locationSearchInputRef?.current?.focus()
    }
  }, [showLeadsModal])

  return (
    <Box mt={2}>
      <Text
        size='xxs'
        weight='semibold'
        variantCount='500'
        variant='neutralWarm'
        transform='uppercase'
        className={styles.subHeader}
      >
        {intl.formatMessage(MSG.searchCount, { count: leadListings.length })}
      </Text>
      <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={4} mb={4}>
        {displayedResults.map(listing => (
          <GridItem key={listing.id} height='100%' display='flex' flexDirection='column'>
            <ListingCardNew listing={listing} setShowLeadsModal={setShowLeadsModal} />
          </GridItem>
        ))}
      </Grid>
      <SearchLeadCard listings={leadListings} emailInputRef={emailInputRef} />
      <Drawer
        trapFocus={false}
        isOpen={showLeadsModal}
        placement='bottom'
        tracking={{
          guid: 'bnDEASfaamdHBZE89d2nqc',
          ui_context: HOME_SEARCH_LANDING_PAGE,
          descriptor: 'Lead form drawer'
        }}
        onClose={() => setShowLeadsModal(false)}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody p={0}>
            <SearchLeadCard listings={leadListings} emailInputRef={emailInputRef} isDrawer />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  )
}
