import * as Sentry from '@sentry/browser'
import get from 'lodash/get'
import { getLocations } from '../api/mikasa/requests'
import { SET_FILTER } from './actionTypes'

export const setFilter = filter => ({
  type: SET_FILTER,
  filter
})

export const loadSuggestedLocations = params => dispatch => {
  getLocations(params)
    .then(resp => {
      const location = get(resp, 'data.data', [])
      dispatch(
        setFilter({
          location: location[0],
          order: 'DAYS_ON_MARKET, asc'
        })
      )
    })
    .catch(error => {
      Sentry.captureException(error)
    })
}
